import type { ConceptDetailsHandlers } from './useConceptDetailsHandlers.types';
import type { ConceptDetailsProps } from '../ConceptDetails.types';
import type { ConceptDetailsData } from './useConceptDetailsData.types';
import type { NormalizedConcept } from 'shared/features/concepts/concepts.apiV2.types';
import type { DocumentDataFromResponse } from 'shared/features/documentData/documentData.types';
import type { useConceptDetailsMutations } from './useConceptDetailsMutations';
import type { useConceptDetailsQueries } from './useConceptDetailsQueries';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../ConceptDetails.constants';
import * as helpers from './useConceptDetailsHandlers.helpers';
import * as metaConceptsApiHelpers from 'shared/features/metaConcepts/metaConcepts.api.helpers';
import { normalizeConcept } from 'shared/features/concepts/concepts.apiV2.helpers';
import ROUTES from 'admin/constants/routes';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from 'admin/containers/ContentManagement/ContentManagement.constants';

import { useMetaConceptsApi } from 'shared/features/metaConcepts/hooks';
import { useConceptsReduxActions } from 'shared/features/concepts/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';
import { normalizeRequirementTaxonomy } from 'shared/features/requirementTaxonomies/requirementTaxonomies.api.helpers';
import { REQUIREMENT_TAXONOMY_DETAILS } from 'admin/containers/ConceptPage/elements/ConceptDetails/elements/RequirementTaxonomy/RequirementTaxonomy.constants';
import { RequirementTaxonomyQuantifiableOptions } from '../elements/RequirementTaxonomy/hooks/useRequirementTaxonomyQuantifiableOptions.types';
import { useRequirementTaxonomyStringencyOptions } from '../elements/RequirementTaxonomy/hooks/useRequirementTaxonomyStringencyOptions';

export const useConceptDetailsHandlers = ({
  props,
  localState,
  localActions,
  queries,
  mutations
}: {
  props: ConceptDetailsProps;
  localState: ConceptDetailsData['localState'];
  localActions: ConceptDetailsData['localActions'];
  queries: ReturnType<typeof useConceptDetailsQueries>;
  mutations: ReturnType<typeof useConceptDetailsMutations>;
}): ConceptDetailsHandlers => {
  const location = useLocation();
  const history = useHistory();
  const metaConceptsApi = useMetaConceptsApi();
  const conceptsReduxActions = useConceptsReduxActions();
  // This is necessary to provide correct dependencies to useEffect and not create infinity loop
  const { mutate: mutateGetDocuments } = mutations.getDocuments;

  const handleSearchDocs: ConceptDetailsHandlers['handleSearchDocs'] = () => {
    const docIds = localState.docIdsSearch.split(', ');
    mutateGetDocuments({ docIds });
  };

  const handleMetaConceptFetch: ConceptDetailsHandlers['handleMetaConceptFetch'] = async input => {
    try {
      const response = await metaConceptsApi.getMetaConcepts({
        multi_input: input,
        limit: constants.META_CONCEPTS_COUNT_PER_REQUEST
      });

      return metaConceptsApiHelpers.formatMetaCategoriesOptions(response);
    } catch (e) {
      return [];
    }
  };

  const handleChangeMetaConcept: ConceptDetailsHandlers['handleChangeMetaConcept'] = value => {
    if (value) {
      localActions.setDetails({
        ...localState.details,
        metaConcept: {
          id: (value as uiLib.SelectOption).value,
          name: (value as uiLib.SelectOption).label
        } as NormalizedConcept['metaConcept']
      });
    }
  };

  const handleCalculateConcept: ConceptDetailsHandlers['handleCalculateConcept'] =
    useCallback(() => {
      const formatDetails = props.isNewMode
        ? constants.CONCEPT_DETAILS_DEFAULT
        : normalizeConcept(props.concept);
      localActions.setDetails(formatDetails);
    }, [props.isNewMode, localActions, props.concept]);

  const handleAddTaxonomy: ConceptDetailsHandlers['handleAddTaxonomy'] = useCallback(() => {
    const taxonomy_to_add = props.isNewMode
      ? REQUIREMENT_TAXONOMY_DETAILS
      : normalizeRequirementTaxonomy(localState.taxonomy);
    localActions.setTaxonomy(taxonomy_to_add);
  }, [props.isNewMode, localActions, localState.taxonomy]);

  const handleToggleEditMode: ConceptDetailsHandlers['handleToggleEditMode'] = () => {
    if (localState.isEditMode) {
      handleCalculateConcept();
      localActions.setDocIdsSearch('');
      handleRequirementTypeChange();
    }
    localActions.setIsEditMode(!localState.isEditMode);
  };

  const handleChanges: ConceptDetailsHandlers['handleChanges'] = (name, value) => {
    localActions.setDetails({
      ...localState.details,
      [name]: value
    });
  };

  const handleTaxonomyChanges: ConceptDetailsHandlers['handleTaxonomyChanges'] = (name, value) => {
    localActions.setTaxonomy({
      ...localState.taxonomy,
      [name]: value
    });
  };

  const handleSaveChanges: ConceptDetailsHandlers['handleSaveChanges'] = async () => {
    localActions.setErrorFields({});
    const { isError: isConceptError, errorFields: conceptErrorFields } =
      helpers.checkConceptChangeErrors(localState.details);
    const { isError: isRequirementTypeError, errorFields: requirementTypeErrorFields } =
      helpers.checkRequirementTypeInfo({
        documents: localState.documents
      });

    localActions.setErrorFields({ ...conceptErrorFields, ...requirementTypeErrorFields });

    if (!isConceptError && !isRequirementTypeError) {
      localActions.setIsEditMode(false);

      const conceptId = await props.onChangeConcept(localState.details);

      const updateRequirementType = helpers.collectRequirementTypeInfoUpdate({
        requirementType: queries.fetchRequirementType.data,
        isActive: localState.isActive,
        documents: localState.documents
      });

      const updateRequirementTaxonomy = helpers.collectRequirementTaxonomyInfoUpdate({
        requirementTaxonomy: queries.fetchRequirementTaxonomy.data,
        newTaxonomy: localState.taxonomy
      });

      if (localState.details.isRequirementType) {
        if (Object.keys(updateRequirementType).length > 0) {
          mutations.updateRequirementType.mutate({
            ...updateRequirementType,
            concept_id: Number(conceptId)
          });
        }
        if (Object.keys(updateRequirementTaxonomy).length > 0) {
          mutations.updateRequirementTaxonomy.mutate({
            ...updateRequirementTaxonomy,
            concept_id: Number(conceptId)
          });
        }
      }

      localActions.setIsEditMode(false);
      if (conceptId && props.isNewMode) {
        history.push(`${ROUTES.conceptTool}/${conceptId}`);
      }
    }
  };

  const handleToggleConfirmationModal: ConceptDetailsHandlers['handleToggleConfirmationModal'] =
    () => {
      return localActions.setIsConfirmationModalOpen(!localState.isConfirmationModalOpen);
    };

  const handleOnDelete: ConceptDetailsHandlers['handleOnDelete'] = async () => {
    localActions.setIsEditMode(false);
    await conceptsReduxActions.deleteConcept({ id: Number(props.id) });
    history.push(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.CONCEPTS}`,
      location.state
    );
  };

  const handleDeleteTaxonomy: ConceptDetailsHandlers['handleDeleteTaxonomy'] = async () => {
    localActions.setIsEditMode(false);
    await conceptsReduxActions.deleteConcept({ id: Number(props.id) });
    history.push(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.CONCEPTS}`,
      location.state
    );
  };

  const handleRequirementTypeChange: ConceptDetailsHandlers['handleRequirementTypeChange'] =
    useCallback(() => {
      const requirementTypes = queries.fetchRequirementType.data;
      if (requirementTypes && requirementTypes.length) {
        const jurisdictions: uiLib.SelectOption[] = [];
        let documentIds: DocumentDataFromResponse['id'][] = [];

        requirementTypes.forEach(requirementType => {
          jurisdictions.push({
            value: requirementType.jurisdictionId,
            label: requirementType.jurisdictionName
          });
          documentIds = [...documentIds, ...(requirementType.docIds || [])];
        });
        localActions.setIsActive(requirementTypes[0].isActive);
        localActions.setJurisdiction(jurisdictions);
        if (documentIds.length) {
          mutateGetDocuments({ docIds: documentIds.map(String), initial: true });
        }
      }
    }, [queries.fetchRequirementType.data, localActions, mutateGetDocuments]);

  const handleRequirementTaxonomyChange: ConceptDetailsHandlers['handleRequirementTaxonomyChange'] =
    useCallback(() => {
      const requirementTaxonomies = queries.fetchRequirementTaxonomy.data;
      if (requirementTaxonomies && requirementTaxonomies.length) {
        localActions.setTaxonomy(requirementTaxonomies[0]);
      }
    }, [queries.fetchRequirementTaxonomy.data, localActions]);

  const handleChangeStatus: ConceptDetailsHandlers['handleChangeStatus'] = value => {
    localActions.setIsActive((value as uiLib.SelectOption).value);
    localActions.setErrorFields({});
  };

  const handleChangeUnit: ConceptDetailsHandlers['handleChangeUnit'] = value => {
    const taxonomy = localState.taxonomy;
    taxonomy.unit = (value as uiLib.SelectOption).value;
    localActions.setTaxonomy(taxonomy);
    localActions.setErrorFields({});
  };

  const handleChangeQuantifiable: ConceptDetailsHandlers['handleChangeQuantifiable'] = value => {
    const taxonomy = localState.taxonomy;
    taxonomy.quantifiable = (value as uiLib.SelectOption).value;
    localActions.setTaxonomy(taxonomy);
    localActions.setErrorFields({});
  };

  const handleChangeStringency: ConceptDetailsHandlers['handleChangeStringency'] = value => {
    const taxonomy = localState.taxonomy;
    taxonomy.stringency = (value as uiLib.SelectOption).value;
    localActions.setTaxonomy(taxonomy);
    localActions.setErrorFields({});
  };

  const handleDeleteDocument: ConceptDetailsHandlers['handleDeleteDocument'] = async rows => {
    localActions.setDocuments(localState.documents.filter(doc => doc.id !== rows[0]?.doc_id));
  };

  const handleSortDocuments: ConceptDetailsHandlers['handleSortDocuments'] = params => {
    localActions.setDocuments(helpers.sortDocuments(params, localState.documents));
  };

  return {
    handleSearchDocs,
    handleMetaConceptFetch,
    handleChangeMetaConcept,
    handleCalculateConcept,
    handleToggleEditMode,
    handleChanges,
    handleSaveChanges,
    handleToggleConfirmationModal,
    handleOnDelete,
    handleRequirementTypeChange,
    handleChangeStatus,
    handleDeleteDocument,
    handleSortDocuments,
    handleTaxonomyChanges,
    handleDeleteTaxonomy,
    handleAddTaxonomy,
    handleRequirementTaxonomyChange,
    handleChangeUnit,
    handleChangeQuantifiable,
    handleChangeStringency
  };
};
