import type * as documentDataApiTypes from 'shared/features/documentData/documentData.apiV2.types';
import type * as requirementTypeApiTypes from 'shared/features/requirementTypes/requirementTypes.api.types';
import type * as requirementTaxonomyApiTypes from 'shared/features/requirementTaxonomies/requirementTaxonomies.api.types';
import type { ConceptDetailsData } from './useConceptDetailsData.types';
import type { DocumentDataFromResponse } from 'shared/features/documentData/documentData.types';
import type { ConceptDetailsProps } from '../ConceptDetails.types';

import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as documentDataConstants from 'shared/features/documentData/documentData.constants';
import * as constants from '../ConceptDetails.constants';
import * as errorUtils from 'shared/utils/errors';
import * as documentDataApi from 'shared/features/documentData/documentData.apiV2';
import * as requirementTypeApi from 'shared/features/requirementTypes/requirementTypes.api';
import * as requirementTaxonomyApi from 'shared/features/requirementTaxonomies/requirementTaxonomies.api';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'admin/constants/queryKeys';

export const useConceptDetailsMutations = ({
  props,
  localState,
  localActions
}: {
  props: ConceptDetailsProps;
  localState: ConceptDetailsData['localState'];
  localActions: ConceptDetailsData['localActions'];
}) => {
  const queryClient = useQueryClient();

  const getDocuments = useMutation<
    Awaited<documentDataApiTypes.FetchDocumentsDataResponse>,
    Error,
    { docIds: string[]; initial?: boolean }
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.DOCUMENTS_DATA],
    mutationFn: params => {
      return documentDataApi.fetchDocumentsData({
        [documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.EXCLUSIVE_FIELDS]:
          constants.DOCUMENT_REQUEST_FIELDS,
        [documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOC_IDS]: params.docIds
      });
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: (documentsResponse, params) => {
      const documents = (documentsResponse.items ?? []) as DocumentDataFromResponse[];
      const localStateDocuments = params.initial
        ? documents
        : [...localState.documents, ...documents];
      localActions.setDocuments(localStateDocuments);
      if (params.docIds.length > documents.length) {
        localActions.setErrorFields({
          ...localState.errorFields,
          documentSearch: constants.REQUIREMENT_TYPES_ERRORS.DOCUMENT_SEARCH
        });
      } else {
        localActions.setErrorFields({});
        localActions.setDocIdsSearch('');
      }
    }
  });

  const updateRequirementType = useMutation<
    Awaited<requirementTypeApiTypes.ModifyRequirementTypesByConceptResponse>,
    Error,
    requirementTypeApiTypes.ModifyRequirementTypesByConceptPayload[1]
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.REQUIREMENT_TYPES],
    mutationFn: requirementType => {
      const conceptId = Number(props.id) || Number(requirementType.concept_id);
      return requirementTypeApi.updateRequirementTypesByConcept(conceptId, requirementType);
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      queryClient.invalidateQueries([
        COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.REQUIREMENT_TYPES][
          QUERY_SECTIONS.REQUIREMENT_TYPES_EDIT
        ]({ id: Number(props.id) })
      ]);
    }
  });

  const updateRequirementTaxonomy = useMutation<
    Awaited<requirementTaxonomyApiTypes.ModifyRequirementTaxonomiesByConceptResponse>,
    Error,
    requirementTaxonomyApiTypes.ModifyRequirementTaxonomiesByConceptPayload[1]
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.REQUIREMENT_TAXONOMY],
    mutationFn: requirementTaxonomy => {
      const conceptId = Number(props.id) || Number(requirementTaxonomy.concept_id);
      return requirementTaxonomyApi.updateRequirementTaxonomiesByConcept(
        conceptId,
        requirementTaxonomy
      );
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      queryClient.invalidateQueries([
        COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.REQUIREMENT_TAXONOMY][
          QUERY_SECTIONS.REQUIREMENT_TAXONOMY_EDIT
        ]({ id: Number(props.id) })
      ]);
    }
  });

  return {
    getDocuments,
    updateRequirementType,
    updateRequirementTaxonomy
  };
};
